<template>
  <div class="auctionsDashboard js-auctions-box">
    <vue-scroll :ops="ops">
      <div v-if="!addingMode" class="auctionsDashboard__nav">
<!--        <div class="auctionsDashboard__nav-item">-->
<!--          <div class="auctionsDashboard__nav-title">Auctions</div>-->
<!--          <div class="auctionsDashboard__nav-button-wr">-->
<!--            <div-->
<!--              class="auctionsDashboard__nav-button auctionsDashboard__nav-button&#45;&#45;type js-auctions-type-item"-->
<!--              :class="{'active' : showBids}"-->
<!--              data-type="bids"-->
<!--              data-title="Bids"-->
<!--              @click="activateBids"-->
<!--            >-->
<!--              <span class="text">Bids</span>-->
<!--            </div>-->
<!--            <div-->
<!--              class="auctionsDashboard__nav-button auctionsDashboard__nav-button&#45;&#45;type js-auctions-type-item"-->
<!--              :class="{'active' : showOffers}"-->
<!--              data-type="offers"-->
<!--              data-title="Offers (space for sales)"-->
<!--              @click="activateOffers"-->
<!--            >-->
<!--              <span class="text">Offers</span>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
        <div class="auctionsDashboard__nav-item">
          <div v-if="showBids" class="auctionsDashboard__nav-title js-auctions-title">Auctions (Bids)</div>
          <div v-else class="auctionsDashboard__nav-title js-auctions-title">Offers (space for sales)</div>
          <div class="auctionsDashboard__nav-button-wr">
            <div
              class="auctionsDashboard__nav-button"
              :class="{'show' : showBids, 'active': !isHistorical}"
              data-type="bids"
              data-id="bids-in-progress"
              @click="tabActivateBids"
            >
              <span class="text">In progress</span>
            </div>
            <div
              class="auctionsDashboard__nav-button"
              :class="{'show' : showBids, 'active': isHistorical}"
              data-type="bids"
              data-id="bids-historical"
              @click="tabActivateBids"
            >
              <span class="text">Historical</span>
            </div>
            <div
              class="auctionsDashboard__nav-button js-tabNavOffers"
              :class="{'show' : showOffers}"
              data-type="offers"
              data-id="offers-in-progress"
              @click="tabActivateOffers"
            >
              <span class="text">In progress</span>
            </div>
            <div
              class="auctionsDashboard__nav-button js-tabNavOffers"
              :class="{'show' : showOffers}"
              data-type="offers"
              data-id="offers-historical"
              @click="tabActivateOffers"
            >
              <span class="text">Historical</span>
            </div>
          </div>
        </div>
      </div>
      <div v-if="!addingMode" class="auctionsDashboard__content">
        <div class="auctionsDashboard__content-item js-tabContentBid" v-if="showBids && !isHistorical && bids">
          <AuctionsListDashboard
            :type="'bids'"
            :data="bids"
            :addingMode="addingMode"
            @activateAddingMode="activateAddingMode"
            :getBidList="getBidList"
          />
        </div>
        <div class="auctionsDashboard__content-item js-tabContentBid" v-if="showBids && isHistorical">
          <AuctionsListDashboard
            :type="'bids'"
            :data="historicalBids"
            :addingMode="addingMode"
            :isHistorical="isHistorical"
            @activateAddingMode="activateAddingMode"
          />
        </div>
        <div class="auctionsDashboard__content-item js-tabContentOffers"  v-if="showOffers">
          <AuctionsListDashboard
            :type="'offers'"
            :data="offers"
          />
        </div>
        <div class="auctionsDashboard__content-item js-tabContentOffers" v-if="showOffers">
          <AuctionsListDashboard
            :type="'offers'"
            :data="offers"
          />
        </div>
      </div>

      <div v-else>

      <span class="auctionsDashboard__link" @click="addingMode = false">
        <span class="icon"><SvgIcon name="left-arrow"/></span>
        <span class="text">Back to all auctions</span>
      </span>
        <div class="auctionsDashboard__info">
          <div class="auctionsDashboard__info-item">
            <div class="auctionsDashboard__info-item-title">Departure address:</div>
            <div class="auctionsDashboard__info-item-text"><b>Republic Airport (KFRG)</b><br> 7150 Republic Airport,
              Farmingdale, NY 11735
            </div>
          </div>
          <div class="auctionsDashboard__info-item">
            <div class="auctionsDashboard__info-item-title">Arrival address:</div>
            <div class="auctionsDashboard__info-item-text"><b>Orlando International Airport (KMCO)</b><br> 1 Jeff Fuqua
              Boulevard Orlando, Florida 32827
            </div>
          </div>
          <div class="auctionsDashboard__info-item">
            <div class="auctionsDashboard__info-item-title">Passengers:</div>
            <div class="auctionsDashboard__info-item-text"><b>12</b></div>
          </div>
          <div class="auctionsDashboard__info-item">
            <div class="auctionsDashboard__info-item-title">Trip time:</div>
            <div class="auctionsDashboard__info-item-text"><b>2H 15 MIN</b></div>
          </div>
        </div>
        <div class="auctionsDashboard__options">
          <div class="auctionsDashboard__options-item">
            <div class="auctionsDashboard__options-item-title">Time left:</div>
            <div class="auctionsDashboard__options-item-text">8H:21M</div>
          </div>
          <div class="auctionsDashboard__options-item">
            <div class="auctionsDashboard__options-item-title">12 bids:</div>
            <div class="auctionsDashboard__options-item-text"><b>$2400</b></div>
          </div>
          <div class="auctionsDashboard__options-item">
            <form
              class="form form--simple js-ajaxForm"
              data-href="/"
              data-action=""
            >
              <label class="form__label form__label--text price">
                <div class="form__field-wrapper">
                  <input
                    class="form__field"
                    type="text"
                    v-model="price"
                    v-money="money"
                    placeholder="$2500"
                    autocomplete="off"
                    required="required"
                  />
                </div>
              </label>
              <div class="form__label form__label--button">
                <div
                  class="form__button js-popup"
                  data-mod="white-popup out-close"
                  @click="$modal.show('AuctionsConfirmPopup')"
                >
                  <span class="text">Bid</span>
                </div>
              </div>
            </form>
          </div>
          <div class="auctionsDashboard__options-item">
            <div class="auctionsDashboard__book">
              <div class="auctionsDashboard__book-price">$2800</div>
              <a class="auctionsDashboard__book-button" href="#">
                <span class="text">Book now</span>
              </a>
            </div>
          </div>
        </div>
        <div class="auctionsDashboard__aircraft">
          <div class="auctionsDashboard__title">Aircraft</div>
          <div class="auctionsDashboard__aircraft-title">Gulfstream 500 (G500)</div>
          <div class="auctionsDashboard__aircraft-slider">
            <Slider :thumsSlides="4"/>
          </div>
          <div class="auctionsDashboard__aircraft-info">
            <div class="auctionsDashboard__aircraft-list">

              <div
                v-for="(item, index) of details"
                :key="index"
                class="auctionsDashboard__aircraft-list-item"
              >
                <div class="auctionsDashboard__aircraft-list-item-icon">
                  <SvgIcon :name="item.icon"/>
                </div>
                <div class="auctionsDashboard__aircraft-list-item-text">
                  <span class="auctionsDashboard__aircraft-list-item-title">{{item.title}}</span>
                  <a class="auctionsDashboard__aircraft-list-item-link"></a>
                  <span class="auctionsDashboard__aircraft-list-item-value">{{item.value}}</span>
                </div>
              </div>

            </div>
            <div class="auctionsDashboard__aircraft-text">Sed ut perspiciatis, unde omnis iste natus error sit
              voluptatem
              accusantium doloremque laudantium, totam Sed ut perspiciatis, unde omnis iste natus error sit voluptatem
              accusantium doloremque laudantium, totam
            </div>
          </div>
        </div>

      </div>
    </vue-scroll>
    <AuctionsConfirmPopup />
  </div>
</template>

<script>
  import SvgIcon from '@/components/common/SvgIcon/SvgIcon'
  import { Tabs } from '@/js/plugins/tabs'
  import AuctionsListDashboard from '@/components/dashboard/AuctionsListDashboard/AuctionsListDashboard'
  import Slider from '@/components/dashboard/Slider/Slider'
  import AuctionsConfirmPopup from '@/components/common/modals/AuctionsConfirmPopup'
  import { VMoney } from 'v-money'
  import './Auctions.styl'

  export default {
    name: 'Auctions',
    data: () => ({
      addingMode: false,
      showBids: true,
      showOffers: false,
      price: '',
      money: {
        decimal: '.',
        thousands: ',',
        prefix: '$ ',
        precision: 2,
        masked: true,
      },
      details: [
        {
          icon: 'aeroplane',
          title: ' Aircraft Type:',
          value: 'Heavy Jet',
        },
        {
          icon: 'protection',
          title: 'Operator Safety Rating:',
          value: '560',
        },
        {
          icon: 'placeholder2',
          title: 'Home base:',
          value: 'value',
        },
        {
          icon: 'moving',
          title: 'Max Passengers:',
          value: 'Heavy Jet',
        },
        {
          icon: 'chronometer',
          title: 'Hours Flown:',
          value: 'Heavy Jet',
        },
        {
          icon: 'open-folder',
          title: 'Insurance:',
          value: 'clickable area',
          href: '#',
        },
        {
          icon: 'speedometer',
          title: 'Speed:',
          value: 'Heavy Jet',
        },
        {
          icon: 'mountains',
          title: 'Range:',
          value: 'Heavy Jet',
        },
        {
          icon: 'clock2',
          title: 'Year of Manufacture:',
          value: 'value',
        },
      ],
      tabsBid: new Tabs('.js-tabNavBid', '.js-tabContentBid'),
      tabsOffers: new Tabs('.js-tabNavOffers', '.js-tabContentOffers'),
      ops: {
        rail: {
          size: '3px',
        },
        bar: {
          size: '3px',
          background: 'rgba(0,0,0,0.1)',
        },
      },
      isHistorical: false,
    }),
    directives: { money: VMoney },
    computed: {
      bids () {
        return this.$store.state.flyersBid.bidList
      },
      historicalBids () {
        return this.$store.state.flyersBid.historicalBids
      },
      offers () {
        return this.$store.state.auctions.offers
      },
    },
    mounted () {
      if (this.showBids) {
        this.tabsBid.addActiveForMouted()

        this.getBidList()
      } else if (this.showOffers) {
        this.tabsOffers.addActiveForMouted()
      }
    },
    updated () {
      if (this.showBids) {
        this.tabsOffers.removeTabs()
        this.tabsBid.addActiveForMouted()
      } else if (this.showOffers) {
        this.tabsBid.removeTabs()
        this.tabsOffers.addActiveForMouted()
      }
    },
    methods: {
      activateAddingMode () {
        this.addingMode = true
      },
      activateBids () {
        this.showBids = true
        this.showOffers = false
      },
      activateOffers () {
        this.showBids = false
        this.showOffers = true
      },
      tabActivateBids (event) {
        this.isHistorical = event.currentTarget.dataset.id !== 'bids-in-progress'

        this.getBidList()
      },
      tabActivateOffers (event) {
        this.tabsOffers.addActiveForClick(event.currentTarget)
      },
      async getBidList (data) {
        await this.$store.dispatch('getFlyerBidList', {...data, historical: this.isHistorical});
      },
    },
    components: {
      AuctionsListDashboard,
      SvgIcon,
      Slider,
      AuctionsConfirmPopup,
    },
  }
</script>
