<template>
  <modal name="AuctionsConfirmPopup" transition="pop-out" @opened="opened" :adaptive="true">
    <div class="auctionsDashboardConfirm" id="auctionsDashboardConfirm">
      <div class="auctionsDashboardConfirm__inner">
        <div class="auctionsDashboardConfirm__title">Review and confirm</div>
        <div class="auctionsDashboardConfirm__list">
          <div class="auctionsDashboardConfirm__list-item">
            <div class="auctionsDashboardConfirm__list-item-title">Time left:</div>
            <div class="auctionsDashboardConfirm__list-item-text">8H:21M</div>
          </div>
          <div class="auctionsDashboardConfirm__list-item">
            <div class="auctionsDashboardConfirm__list-item-title">12 bids:</div>
            <div class="auctionsDashboardConfirm__list-item-text"><b>$2400</b></div>
          </div>
          <div class="auctionsDashboardConfirm__list-item">
            <div class="auctionsDashboardConfirm__list-item-title">Your bid:</div>
            <div class="auctionsDashboardConfirm__list-item-text"><b>$2600</b></div>
          </div>
        </div>
        <div class="auctionsDashboardConfirm__text">By clicking <b>Confirm Bid</b> you commit to buy this ticket from
          seller if you are the winning bidder
        </div>
      </div>
      <div class="auctionsDashboardConfirm__bottom"><a class="auctionsDashboardConfirm__button" href="#"><span
        class="text">Confirm bid</span></a>
      </div>
      <button class="fancybox-button fancybox-close-small" @click="$modal.hide('AuctionsConfirmPopup')"></button>
    </div>
  </modal>
</template>

<script>
  export default {
    name: 'AuctionsConfirmPopup',
    methods: {
      opened () {
        if (document.querySelector('html').classList.contains('d-mobile')) {
          const popup = document.querySelector('.vm--container')
          document.querySelector('body').appendChild(popup)
        }
      },
    },
  }
</script>
