<template>
  <div class="auctionsDashboard__listWrapper">
    <div v-if="type === 'bids'" class="auctionsDashboard__list auctionsDashboard__list--bids">
      <div class="auctionsDashboard__list-header">
        <div class="auctionsDashboard__list-header-item" data-cell="1">Flight date</div>
        <div class="auctionsDashboard__list-header-item" data-cell="2">Model</div>
        <div class="auctionsDashboard__list-header-item" data-cell="3">From</div>
        <div class="auctionsDashboard__list-header-item" data-cell="4">To</div>
        <div class="auctionsDashboard__list-header-item" data-cell="5">Current bid</div>
        <div class="auctionsDashboard__list-header-item" data-cell="6">Input date</div>
        <div class="auctionsDashboard__list-header-item" data-cell="7">Your bid</div>
      </div>
      <vue-scroll :ops="ops">
        <div class="auctionsDashboard__list-content js-customScroll">
          <div
            v-for="item of data"
            :key="item.id"
            class="auctionsDashboard__list-item"
          >
            <div class="auctionsDashboard__list-item-inner">
              <div class="auctionsDashboard__list-item-column" data-cell="1">
                <div class="auctionsDashboard__list-item-caption">Flight date</div>
                <div class="auctionsDashboard__list-item-title"> {{moment(item.flightDepartureDate).format('M/D/YYYY')}}</div>
              </div>
              <div class="auctionsDashboard__list-item-column" data-cell="2">
                <div class="auctionsDashboard__list-item-caption">Model</div>
                <div class="auctionsDashboard__list-item-title">{{item.model}}</div>
              </div>
              <div class="auctionsDashboard__list-item-column" data-cell="3">
                <div class="auctionsDashboard__list-item-caption">From</div>
                <div class="auctionsDashboard__list-item-title">{{item.departureName}}</div>
              </div>
              <div class="auctionsDashboard__list-item-column" data-cell="4">
                <div class="auctionsDashboard__list-item-caption">To</div>
                <div class="auctionsDashboard__list-item-title">{{item.arrivalName}}</div>
              </div>
              <div class="auctionsDashboard__list-item-column" data-cell="5">
                <div class="auctionsDashboard__list-item-caption">Current bid</div>
                <div class="auctionsDashboard__list-item-title">{{localeBid(item.currentBidValue)}}</div>
              </div>
              <div class="auctionsDashboard__list-item-column" data-cell="6">
                <div class="auctionsDashboard__list-item-caption">Input date</div>
                <div class="auctionsDashboard__list-item-title">{{moment(item.currentBidCreated).format('M/D/YYYY')}}</div>
              </div>
              <div class="auctionsDashboard__list-item-column" data-cell="7">
                <div class="auctionsDashboard__list-item-caption">Your bid</div>
                <div class="auctionsDashboard__list-item-title">{{localeBid(item.prevBidValue)}}</div>
                <div class="auctionsDashboard__list-item-info" v-if="item.currentBidValue > item.prevBidValue && !item.accepted">You were outbid</div>
                <div class="auctionsDashboard__list-item-info" v-if="item.accepted">Accepted</div>
              </div>
            </div>
            <div class="auctionsDashboard__list-item-view-history">
              <span @click="() => getNonActualBidList(item)">View bid history</span>
            </div>
            <div class="auctionsDashboard__list-item-button-wr" v-if="!isHistorical && !item.accepted">
              <button
                class="auctionsDashboard__list-item-button"
                @click="() => setActiveFly(item)"
                :disabled="(!item.updateBidAvailable) || isRenew"
              >
                <span class="text">Update</span>
              </button>
              <span v-if="isRenew">Renew your subscription to make a new bid</span>
              <span v-else-if="!item.updateBidAvailable">New Bid is only available > 1 day prior to flying.</span>
            </div>
          </div>
        </div>
      </vue-scroll>
    </div>
    <div v-else class="auctionsDashboard__list auctionsDashboard__list--offers">
      <div class="auctionsDashboard__list-header">
        <div class="auctionsDashboard__list-header-item" data-cell="0"></div>
        <div class="auctionsDashboard__list-header-item" data-cell="1">From</div>
        <div class="auctionsDashboard__list-header-item" data-cell="2">To</div>
        <div class="auctionsDashboard__list-header-item" data-cell="3">Model</div>
        <div class="auctionsDashboard__list-header-item" data-cell="4">Max Pax</div>
        <div class="auctionsDashboard__list-header-item" data-cell="5">Time left</div>
        <div class="auctionsDashboard__list-header-item" data-cell="6">Spaces/seats/<br>plane</div>
        <div class="auctionsDashboard__list-header-item" data-cell="7">Current bid</div>
        <div class="auctionsDashboard__list-header-item" data-cell="8">Reserve price</div>
        <div class="auctionsDashboard__list-header-item" data-cell="9">buy-it-now price</div>
      </div>
      <vue-scroll :ops="ops">
        <div class="auctionsDashboard__list-content js-customScroll">
          <div
            v-for="item of data"
            :key="item.id"
            class="auctionsDashboard__list-item">
            <div class="auctionsDashboard__list-item-inner">
              <div class="auctionsDashboard__list-item-column" data-cell="0">
                <div
                  class="auctionsDashboard__list-item-img"
                  :style="{backgroundImage: `url(${item.photo})`}"
                ></div>
              </div>
              <div class="auctionsDashboard__list-item-column" data-cell="1">
                <div class="auctionsDashboard__list-item-caption">From</div>
                <div class="auctionsDashboard__list-item-title">{{item.from}}</div>
                <div class="auctionsDashboard__list-item-date">
                  d
                </div>
                <div class="auctionsDashboard__list-item-arrow">
                  <SvgIcon v-if="item.oneWay" name="right-arrow-2"/>
                  <SvgIcon v-else name="double-long-arrows"/>
                </div>
              </div>
              <div class="auctionsDashboard__list-item-column" data-cell="2">
                <div class="auctionsDashboard__list-item-caption">To</div>
                <div class="auctionsDashboard__list-item-title">{{item.to}}</div>
                <div class="auctionsDashboard__list-item-date">
                  {{item.flightDepartureDate}}
                </div>
              </div>
              <div class="auctionsDashboard__list-item-column" data-cell="3">
                <div class="auctionsDashboard__list-item-caption">Model</div>
                <div class="auctionsDashboard__list-item-title">{{item.model}}</div>
              </div>
              <div class="auctionsDashboard__list-item-column" data-cell="4">
                <div class="auctionsDashboard__list-item-caption">Max Pax</div>
                <div class="auctionsDashboard__list-item-title">{{item.maxPax}}</div>
              </div>
              <div class="auctionsDashboard__list-item-column" data-cell="5">
                <div class="auctionsDashboard__list-item-caption">Time left</div>
                <div class="auctionsDashboard__list-item-title">{{item.timeLeft}}</div>
              </div>
              <div class="auctionsDashboard__list-item-column" data-cell="6">
                <div class="auctionsDashboard__list-item-caption">Spaces/seats/<br>plane</div>
                <div class="auctionsDashboard__list-item-title">{{item.seats}}</div>
              </div>
              <div class="auctionsDashboard__list-item-column" data-cell="7">
                <div class="auctionsDashboard__list-item-caption">Current bid</div>
                <div class="auctionsDashboard__list-item-title">{{item.currentBidValue}}</div>
                <div class="auctionsDashboard__list-item-info">{{item.currentBidDate}}</div>
              </div>
              <div class="auctionsDashboard__list-item-column" data-cell="8">
                <div class="auctionsDashboard__list-item-caption">Reserve price</div>
                <div class="auctionsDashboard__list-item-title">{{item.reservePrice}}</div>
              </div>
              <div class="auctionsDashboard__list-item-column" data-cell="9">
                <div class="auctionsDashboard__list-item-caption">buy-it-now price</div>
                <div class="auctionsDashboard__list-item-title">{{item.buyItNow}}</div>
              </div>
            </div>
            <div class="auctionsDashboard__list-item-button-wr">
              <a href="#" class="auctionsDashboard__list-item-button">
                <span class="text">Change reserve price</span>
              </a>
              <a href="#" class="auctionsDashboard__list-item-button">
                <span class="text">Contact admin</span>
              </a>
            </div>
          </div>
        </div>
      </vue-scroll>
    </div>
    <Teleport to="body">
      <NonActualBidsPopup
        :isAdmin=false
      />
      <BidRequestEmptyPopup
        :previousBid="activeTrip.currentBidValue"
        :types="{bid: true}"
        :isLoading="isLoading"
        @publishBid="publishBid"
      />
    </Teleport>
    <InfoPopup/>
  </div>
</template>

<script>
  import SvgIcon from '@/components/common/SvgIcon/SvgIcon'
  import moment from 'moment';
  import BidRequestEmptyPopup from '@/components/common/modals/BidRequestEmptyPopup.vue';
  import InfoPopup from '@/components/common/modals/InfoPopup.vue';
  import NonActualBidsPopup from '@/components/common/modals/NonActualBidsPopup.vue';
  import Teleport from 'vue2-teleport';

  export default {
    props: ['type', 'data', 'addingMode', 'getBidList', 'isHistorical'],
    name: 'AuctionsListDashboard',
    data: () => ({
      ops: {
        rail: {
          size: '3px',
        },
        bar: {
          size: '3px',
          background: 'rgba(0,0,0,0.1)',
        },
      },
      activeTrip: {},
      isRenew: false,
      isLoading: false,
    }),
    mounted () {
      const id = this.$route.query.id;

      if (id && this.type === 'bids') {
        const item = this.data.find(item => item.id === id)

        if (item) {
          this.activeTrip = item;

          this.$modal.show('publishBid')
        }
      }

      this.isRenew = !this.$store.getters.getNameActiveSubscriptionPlanName
    },
    methods: {
      moment,
      async getNonActualBidList (data) {
        this.$modal.show('nonActualBids')

        await this.$store.dispatch('getNonActualBidList', data.flightId);
      },
      localeBid (bid) {
       if (bid) {
        return bid.toLocaleString('en-US', {style: 'currency', currency: 'USD'})
       }
       return ''
      },
      setActiveFly (fly) {
        this.activeTrip = fly
        this.$modal.show('publishBid');
      },
      async publishBid (bid, willingToSplitWithGroup) {
        this.isLoading = true

        const {exclusiveTotalPrice, flightId} = this.activeTrip

        const data = {
          flightId,
          bidValue: +bid,
          exclusiveTotalPrice,
          willingToSplitWithGroup,
        };
        const res = await this.$store.dispatch('updateBid', data)
        this.$modal.hide('publishBid')
        this.isLoading = false

        if (res && res.data.isSuccessfull) {
          this.showInfoDialog('Bid successfully updated');
          this.getBidList();
        } else {
          if (res) {
            console.log(res.data.message)
          }

          this.showInfoDialog('Something went wrong');
        }
      },
      showInfoDialog (title) {
        this.$modal.show('InfoPopup', { title: title });
      },
    },
    components: {
      NonActualBidsPopup,
      BidRequestEmptyPopup,
      SvgIcon,
      Teleport,
      InfoPopup,
    },
  }
</script>
